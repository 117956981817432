/**
 * * Contains analytics event names definitions
 * @type {{CTA_CLICKED: string, MAX_SCROLL: string, EMAIL_SUBMITTED: string, QUESTION_COMPLETED: string, QUOTE_RECEIVED: string, PACKAGE_SELECTED: string, TERM_SEARCHED: string, RESULT_CLICKED: string, LEGAL_BANNER_DISMISSED: string, EMAILED_ESTIMATES: string, RETURNED_TO_ANSWERS: string, ADJUSTED_PRICE: string }}
 */

export const EVENT_NAMES = {
  CTA_CLICKED: 'CTA Clicked',
  SCROLL_DEPTH_RECORDED: 'Scroll Depth Recorded',
  SECONDARY_CTA_CLICKED: 'Secondary CTA Clicked',
  NAV_ITEM_CLICKED: 'Nav Item Clicked',
  EMAIL_SUBMITTED: 'Email Submitted',
  QUESTION_COMPLETED: 'Question Completed',
  QUOTE_RECEIVED: 'Quote Received',
  PACKAGE_SELECTED: 'Package Selected',
  PARTNER_AGENT_CREATE_SUBMITTING: 'Partner Agent Create Submitting',
  AGREEMENT_DOC_LINK_CLICKED: 'Agreement Doc Link Clicked',
  TERM_SEARCHED: 'Search Term Searched',
  RESULT_CLICKED: 'Search Result Clicked',
  LEGAL_BANNER_DISMISSED: 'Legal Banner Dismissed',
  EMAILED_ESTIMATES: 'Emailed Estimates',
  RETURNED_TO_ANSWERS: 'Returned To Edit Answers',
  ADJUSTED_PRICE: 'Adjusted Estimate Price',
  AGENT_LEAD_SUBMITTED: 'Agent Lead Submitted',
  AGENT_PHONE_CLICKED: 'Agent Phone Clicked',
  EXPERIMENT_VIEWED: 'Experiment Viewed',
  PAGE_LOADED: 'Page Loaded',
  ZIP_CODE_CHECK: `Zip Code Check`,
  REGION_SELECTED: `Region Selected`,
  RESOURCE_CLICKED: 'Resource Clicked',
  RESOURCE_CONTENT_CLICKED: 'Resource Content Clicked',
  RESOURCE_ACCORDION_CLICKED: 'Resource Accordion Clicked',
  TRUST_PILOT_REVIEW_CLICKED: 'Trust Pilot Review Clicked',
  EXPERIMENT_ACTIVATED: 'Experiment Activated',
  FEATURE_DECISION_MADE: 'Feature Decision Made',
  ACCORDION_EXPANDED: 'Accordion Expanded',
  WEB_VITALS: 'Web Vitals Recorded',
  PRIVACY_CENTER_USER_OPTED_IN: 'Privacy Center User Opted In',
  PRIVACY_CENTER_AIRGAP_MISSING: 'Privacy Center AirGap Missing',
  DEFAULT_COVERAGE_AND_TERM_SET: 'Default Coverage and Term Set',
  INITIAL_QUOTES: 'Initial Quotes Received',
  COVERAGE_CHANGED: 'Coverage Changed',
  TERM_CHANGED: 'Term Changed',
  INPUT_FORM_TOUCHED: 'Input Form Touched',
  USER_REDIRECTED_TO_MAIN_APP: 'User Redirected To Main App',
  ESTIMATES_CONTINUE_TO_PRICES: 'Estimates Continue To Prices',
  ESTIMATES_CONTINUE_TO_SALES: 'Estimates Continue To Sales',
  USER_PREFILLED: 'User Prefilled',
  ESTIMATES_CONTINUE_TO_PRICES_ERROR: 'Estimates Continue To Prices Error',
  ESTIMATES_CONTINUE_TO_SALES_ERROR: 'Estimates Continue To Sales Error',
  USER_INTERACTED_WITH_FIELD: 'User Interacted With Field',
  ESTIMATES_CONTINUE_TO_LEAD_FORM: 'Estimates Continue To Lead Form',
  LEAD_FORM_SUBMITTED: 'Lead Form Submitted',
  WILLS_MODAL_FORM_SUBMITTED: 'Wills Modal Form Submitted',
  WILLS_MODAL_DUAL_CTA_OPTION_SELECTED: 'Wills Modal Dual CTA Option Selected',
} as const

/**
 * * Contains module names for analytics event properties
 * @type {{HOMEPAGE: string, HEADER: string, QUOTE_WIDGET: string, INTENT_HOMEPAGE: string, INTENT_BALANCED: string, NAVBAR: string, SEARCH_INDEX: string, ESTIMATE_WIDGET: string}}
 */

export const MODULES = {
  HOMEPAGE: 'Homepage',
  HEADER: 'Header',
  INTENT_HOMEPAGE: 'Intent Homepage',
  INTENT_BALANCED: 'Intent Header Balanced',
  NAVBAR: 'Navbar',
  SEARCH_INDEX: 'Search Index',
  PARTNER_SIGNUP: 'Partner Signup',
  ESTIMATE_WIDGET: 'Estimate Widget',
  HEADER_VERSATILE: 'Header Versatile',
  ESTIMATE_EXPERIENCE: 'Estimate Experience',
  AGENT_LEAD_FORM: 'Agent Lead Form',
  AGENT_LANDING_PAGE: 'Agent Landing Page',
  BANNER: 'Banner Text',
  AGENT_RESOURCES: 'Agent Resources',
  TWO_COLUMN_COMPARISON: 'Two Column Comparison Chart',
  TRUST_PILOT: 'Trust Pilot',
  STAR_TESTIMONIAL: 'Star Testimonial',
  COMPARISON_CHART_YES_NO: 'Comparison Chart Yes No',
  WILLS_PLANS: 'Wills Plans',
  VALUE_PROPS: 'Value Props',
  TO_MAIN_APP: 'To Main App',
  PLAN_BENEFITS: 'Plan Benefits',
} as const

type ModulesType = typeof MODULES
type ModuleKeys = keyof ModulesType
export type ModuleValues = ModulesType[ModuleKeys]

/**
 * * Contains button names for analytics event properties
 * @type {{CTA: string, MAIN_CTA: string, LEARN_MORE: string, APPLY_NOW: string, CONTINUE: string, SAVE: string, EDIT: string, START_APPLYING: string}}
 */

export const BUTTONS = {
  CTA: 'CTA',
  MAIN_CTA: 'Main CTA',
  LEARN_MORE: 'Learn more',
  APPLY_NOW: 'Apply Now',
  SAVE: 'Save estimates',
  EDIT: 'Edit answers',
  CALCULATE: 'Calculate',
  START_APPLYING: 'Start applying',
  GET_QUOTE: 'Get a quote',
} as const

/**
 * * Contains analytics event properties definitions for the EstimateWidget module
 * @type {{GENDER_COMPLETED: string, TOBACCO_COMPLETED: string, HEALTH_COMPLETED: string, ZIP_CODE_COMPLETED: string, BIRTH_DATE_COMPLETED: string, ZIP_CODE_CHECK: string, REGION_SELECTED: string, CTA_CLICKED: string}}
 */
export const ESTIMATE_WIDGET = {
  GENDER_COMPLETED: `Gender Completed`,
  TOBACCO_COMPLETED: `Tobacco Completed`,
  HEALTH_COMPLETED: `Health Completed`,
  ZIP_CODE_COMPLETED: `Zip Code Completed`,
  BIRTH_DATE_COMPLETED: `Birth Date Completed`,
  ZIP_CODE_CHECK: `Zip Code Check`,
  REGION_SELECTED: `Region Selected`,
  ESTIMATED_CREDIT_COMPLETED: `Estimated Credit Completed`,
} as const

/**
 * * Contains analytics event properties definitions for the IntentHeaderBalanced module
 * @type {{PRICE: string, LEARN: string, APPLY: string, LEFT_TOP: string, MIDDLE: string, RIGHT_BOTTOM: string}}
 */

export const INTENT_HEADER_BALANCED = {
  PRICE: 'Price',
  LEARN: 'Learn',
  APPLY: 'Apply',
  LEFT_TOP: 'Left/Top',
  MIDDLE: 'Middle',
  RIGHT_BOTTOM: 'Right/Bottom',
}

export let ETHOSPIXELID = '115294479145483'
if (process.env.NEXT_PUBLIC_ETHOS_ENV === 'production') {
  ETHOSPIXELID = '866816593477328'
}

export const ATTRIBUTION_COOKIE_NAME = 'ETHOS-TRK'
